@import 'https://fonts.googleapis.com/css?family=Lato';

@import 'https://fonts.googleapis.com/css?family=Staatliches';

@font-face {
    font-family: '04b11';
    font-style: normal;
    font-weight: normal;
    src: url('./assets/fonts/04b11.woff2') format('woff2');
}

body {
    background: rgb(48, 0, 81);
    background-image: url('./assets/img/sokoboxes.svg'),
    linear-gradient(to right, rgb(12, 7, 41) 66%, rgb(0, 0, 81));
    background-size: 55rem, 100%;
    background-repeat: repeat;
}

.sokoboxes-title {
    font-family: '04b11' !important;
    text-transform: uppercase;
}

p {
    line-height: 1.1;
}

h3 {
    font-family: 'Staatliches' !important;
    font-size: 1.4rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    color: #4530b9;
    margin-top: 0.5rem !important;
}

@media (min-width: 992px) {
    #mainNav {
        background-image: linear-gradient(
                rgba(0, 0, 0, 1),
                rgba(0, 0, 0, 0)
        ) !important;
    }
}

img.screenshot {
    image-rendering: pixelated;
}

button.btn-card-game-carousel {
    color: #212529;
    background-color: #fde674;
    border-color: #fde674;
}

button.btn.btn-card-game-carousel.focus,
button.btn.btn-card-game-carousel:focus {
    box-shadow: none;
}

button.btn.btn-card-game-carousel.active,
button.btn.btn-card-game-carousel:active {
    background-color: #c0af58;
}

.card-img-container {
    position: relative;
}

.card-img-container .spinner {
    position: absolute;
    left: calc(50% - 0.5rem);
    top: calc(50% - 0.5rem);
}

.semitransparent {
    opacity: 0.4;
}

pre {
    white-space: pre-wrap;
    word-wrap: break-word;
}

code {
    font-family: MyFancyCustomFont, monospace;
    font-size: inherit;
}

pre > code,
p > code,
li > code,
dd > code,
td > code {
    background: #ffeff0;
    word-wrap: break-word;
    box-decoration-break: clone;
    padding: .1rem .3rem .2rem;
    border-radius: .2rem;
}

@media (max-width: 992px) {
    .btn-slide {
        font-size: 1rem;
    }
}

@media (min-width: 992px) {
    img.max-width-320px {
        max-width: 320px;
    }

    img.cassette-tape-front {
        max-width: 602px;
    }
}
